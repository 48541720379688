import { useFrappeFileUpload } from 'frappe-react-sdk'
import { useState } from 'react'
import { FRAPPE_APIS } from '../api.config'
import { SISClassFeedPhoto } from '@/types/SIS/SISClassFeedPhoto'

export const useUploadFeedbackFile = () => {
  const [loading, setLoading] = useState(false)
  const { upload } = useFrappeFileUpload()

  const handleUpload = async (id: string, files: File[]) => {
    // console.log('FILES INCOME', files)
    try {
      setLoading(true)
      let uploads = files.map((file) =>
        upload(
          file,
          {
            isPrivate: true,
            doctype: 'SIS Feedback',
            otherData: {
              feedback_id: id,
            },
            fieldname: 'photo',
          },
          FRAPPE_APIS.UPLOAD_FEEDBACK_FILE.METHOD_STRING,
        ),
      )
      await Promise.all(uploads)
      return Promise.resolve(true)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return { loading, handleUpload }
}
