import { useAuthContext } from '@/lib/auth/useAuth'
import { useCallback, useMemo } from 'react'
import { buildUrlWithParams, cleanPath } from '@/lib/utils/common'
import { USER_ROLE } from '@/lib/auth/auth-types'

type ParamsType = Record<string, string>

const useGetRoutes = () => {
  const { prefixRoute, userRole } = useAuthContext()

  const refinedUrlWithRole = useCallback(
    (url: string, params?: ParamsType) =>
      cleanPath(buildUrlWithParams(`/${prefixRoute ?? ''}` + url, params)),
    [prefixRoute],
  )

  const refinedUrl = useCallback(
    (url: string, params?: ParamsType) =>
      cleanPath(buildUrlWithParams(url, params)),
    [prefixRoute],
  )

  const getRoleBasedRoute = useCallback(
    (role: Record<string, string>) =>
      role[
        userRole && Object.keys(role).includes(userRole) ? userRole : 'default'
      ],
    [userRole],
  )

  const getRoute = useMemo(
    () => ({
      home: (params?: ParamsType) => refinedUrlWithRole('/', params),
      dashboard: (params?: ParamsType) =>
        refinedUrlWithRole('/dashboard', params),
      news: (params?: ParamsType) => refinedUrlWithRole('/news', params),
      newsDetail: (id: string, params?: ParamsType) =>
        refinedUrlWithRole(`/news/${id}`, params),
      menu: (params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole(`/menu`, params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(`/student/menu`, params),
        }),
      menuDetail: (id: string, params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole(`/menu/${id}`, params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `/student/menu/${id}`,
            params,
          ),
        }),
      activities: (params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole('/activities', params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            '/student/activities',
            params,
          ),
        }),
      activityDetail: (id: string, params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole(`/activities/${id}`, params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `/student/activities/${id}`,
            params,
          ),
        }),
      policy: (params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole(`/policy-documents`, params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `forms/policy-documents`,
            params,
          ),
        }),
      policyDetail: (id: string, params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole(`/policy-documents/${id}`, params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `/forms/policy-documents/${id}`,
            params,
          ),
        }),
      attendance: (params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole(`/attendance`, params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `/student/attendance`,
            params,
          ),
        }),
      attendanceClasses: (params?: ParamsType) =>
        refinedUrlWithRole('/attendance/classes', params),
      newAttendanceClassDetail: (params?: ParamsType) =>
        refinedUrlWithRole('/attendance/classes/new-record', params),
      editAttendanceClassDetail: (id: string, params?: ParamsType) =>
        refinedUrlWithRole(`/attendance/classes/${id}`, params),
      attendanceSubjects: (params?: ParamsType) =>
        refinedUrlWithRole('/attendance/subjects', params),
      newAttendanceSubjectDetail: (params?: ParamsType) =>
        refinedUrlWithRole('/attendance/subjects/new-record', params),
      editAttendanceSubjectDetail: (id: string, params?: ParamsType) =>
        refinedUrlWithRole(`/attendance/subjects/${id}`, params),
      timetable: (params?: ParamsType) =>
        getRoleBasedRoute({
          default: refinedUrlWithRole(`/timetable`, params),
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `/student/timetable`,
            params,
          ),
        }),
      studentReportsTree: (path?: string, params?: ParamsType) =>
        getRoleBasedRoute({
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `/student/reports/tree/${path || ''}`,
            params,
          ),
        }),
      studentReportsBlob: (path?: string, params?: ParamsType) =>
        getRoleBasedRoute({
          [USER_ROLE.GUARDIAN]: refinedUrlWithRole(
            `/student/reports/blob/${path || ''}`,
            params,
          ),
        }),
      settings: (params?: ParamsType) =>
        refinedUrlWithRole('/settings', params),
      login: (params?: ParamsType) => refinedUrl('/auth/login', params),
      base: (url: string, params?: ParamsType) => refinedUrl(url, params),
    }),
    [prefixRoute],
  )
  return { getRoute }
}

export default useGetRoutes
