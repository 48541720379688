import React, { FC, HTMLAttributes } from 'react'
import Logo from '@atoms/logo'
import { Separator } from '@atoms/separator'
import NavMenu, { ItemOptionsType } from '@molecules/nav-menu'
import { ScrollArea } from '@atoms/scroll-area'
import { StudentCard } from '@features/student/student-card'
import { cn } from '@/core/utils/shadcn-utils'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useTheme } from '@/lib/shadcn/theme-provider'
import { useChildren } from '@/lib/student/children-provider'

const ScrollAreaStyled = styled(ScrollArea)`
  & > div > div {
    height: 100%;
  }
`
export type SideBarProps = HTMLAttributes<HTMLDivElement> & {
  mainMenu?: ItemOptionsType[]
  bottomMenu?: ItemOptionsType[]
}

export const SideBar: FC<SideBarProps> = ({
  mainMenu,
  bottomMenu,
  className,
}) => {
  const { current } = useChildren()
  const location = useLocation()
  const { theme } = useTheme()

  return (
    <div
      className={cn(
        'sticky left-0 top-0 h-screen basis-[256px] border-r bg-card ',
        className,
      )}
    >
      <ScrollAreaStyled className="h-screen">
        <div className="flex h-full flex-col justify-between px-4 py-2">
          <div className="flex h-full flex-col">
            <div className="relative">
              <Link to="/">
                <Logo
                  className="mx-auto mb-5 h-auto w-full max-w-[120px]"
                  variant={theme === 'dark' ? 'white' : 'default'}
                />
              </Link>
            </div>
            {current && (
              <>
                <StudentCard size="small" />
                <Separator className="my-2" />
              </>
            )}
            {mainMenu && (
              <NavMenu
                items={mainMenu}
                active={(item) =>
                  item.path ? location.pathname.includes(item.path) : false
                }
                activeClassName={{
                  text: 'text-white',
                  background:
                    '[background:linear-gradient(269.28deg,#009684_0%,#006882_98.08%)] shadow-md',
                }}
              />
            )}
          </div>
          {bottomMenu && (
            <NavMenu
              className="my-2"
              items={bottomMenu}
              active={(item) =>
                item.path ? location.pathname.includes(item.path) : false
              }
              activeClassName={{
                text: 'text-white',
                background:
                  '[background:linear-gradient(269.28deg,#009684_0%,#006882_98.08%)] shadow-md',
              }}
            />
          )}
        </div>
      </ScrollAreaStyled>
    </div>
  )
}
