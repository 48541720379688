import { useGetCourseClassesByUser } from '@/api/courseClass/use-get-course-classes-by-user'
import { useGetSchoolClassesByUser } from '@/api/schoolClass/use-get-school-classes-by-user'
import { CourseClassExtend } from '@/types/Extends/CourseClass'
import { SchoolClassExtend } from '@/types/Extends/SchoolClass'
import { SISCourseClass } from '@/types/SIS/SISCourseClass'
import { SISSchoolClass } from '@/types/SIS/SISSchoolClass'
import { SISStudent } from '@/types/SIS/SISStudent'
import { useFrappeGetCall } from 'frappe-react-sdk'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useAuthContext } from '@/lib/auth/useAuth'

interface TeacherContextProps {
  schoolClasses?: SchoolClassExtend[]
  courseClasses?: CourseClassExtend[]
}

export const TeacherContext = createContext<TeacherContextProps>({
  schoolClasses: undefined,
  courseClasses: undefined,
})

export const useTeacher = () => {
  const context = React.useContext(TeacherContext)
  if (!context) {
    throw new Error('useChildren must be used within a TeacherProvider')
  }
  return context
}

export const TeacherProvider = ({ children }: React.PropsWithChildren) => {
  const { currentUser } = useAuthContext()
  const {
    data: schoolClasses,
    isLoading: schoolClassLoading,
    error: errSchoolClasses,
  } = useGetSchoolClassesByUser(!currentUser)
  const {
    data: courseClasses,
    isLoading: courseClassesLoading,
    error: errCourseClasses,
  } = useGetCourseClassesByUser(!currentUser)

  return (
    <>
      {useMemo(
        () => (
          <TeacherContext.Provider
            value={{
              schoolClasses: schoolClasses,
              courseClasses: courseClasses,
            }}
          >
            {children}
          </TeacherContext.Provider>
        ),
        [JSON.stringify(schoolClasses), JSON.stringify(courseClasses)],
      )}
    </>
  )
}
