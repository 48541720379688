import { useFrappePostCall, useFrappePutCall } from 'frappe-react-sdk'
import { FRAPPE_APIS } from '../api.config'
import { replaceUndefinedWithNull } from '@/lib/utils/common'
import { useAuthContext } from '@/lib/auth/useAuth'

export type ProfilePayload = {
  last_name: string
  street: string
  common_name: string
  date_of_birth: string
  nationality: string
  email: string
  phone_number: string
  address: string
  province: string
  provinceCode: string
  district: string
  districtCode: string
  ward: string
  wardCode: string
  company: string
  job_title: string
}
export const useUpdateProfile = () => {
  const { updateUserInfo } = useAuthContext()
  const { call: submitCreate, loading } = useFrappePutCall<{
    message: any
  }>(FRAPPE_APIS.UPDATE_PROFILE.METHOD_STRING)

  const handleSubmit = async (payload: ProfilePayload) => {
    try {
      const result = await submitCreate(
        replaceUndefinedWithNull({
          ...payload,
          address: `${payload.street}${payload.ward ? `, ${payload.ward}` : ''}${payload.district ? `, ${payload.district}` : ''}${payload.province ? `, ${payload.province}` : ''}`,
        }),
      )
      updateUserInfo()
      return Promise.resolve(result.message)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return { handleSubmit, loading }
}
