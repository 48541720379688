import {
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FC,
} from 'react'
import { cn } from '@/core/utils/shadcn-utils'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@atoms/dialog'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@atoms/checkbox'
import { ScrollArea } from '@atoms/scroll-area'
import { PDFReader } from '@molecules/pdf-reader'
import env from '@/config/env'
import { PageChangeEvent } from '@react-pdf-viewer/core'
import { Button } from '@atoms/button'
import { CheckTermsConditions } from './check-terms-conditions'
import { useAcceptTerms } from '@/api/account/use-accept-terms'
import { Loader2 } from 'lucide-react'
import { useAuthContext } from '@/lib/auth/useAuth'

export type TermsModalProps = HTMLAttributes<HTMLDivElement> & {
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export const TermsModal: FC<TermsModalProps> = ({
  open: openValue,
  onOpenChange,
  className,
  children,
}: TermsModalProps) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { acceptTerms, isRequesting } = useAuthContext()
  const [isAccept, setIsAccept] = useState(false)

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    onOpenChange?.(isOpen)
  }

  const onSubmit = async () => {
    await acceptTerms()
    handleOpenChange(false)
  }

  useEffect(() => {
    setOpen(openValue || false)
  }, [openValue])

  return (
    <Dialog open={open}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="top-[50%] flex h-[85vh] w-[1024px] max-w-full translate-y-[-50%] flex-col px-1 pb-3 pt-1 md:h-[90vh] md:px-3 [&>button]:hidden">
        <DialogHeader className="hidden">
          <DialogTitle></DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="flex-1 px-2 pt-3">
          <CheckTermsConditions
            onChecked={setIsAccept}
            action={
              <Button
                type="submit"
                className="space-x-2"
                disabled={!isAccept || isRequesting}
                onClick={onSubmit}
              >
                {isRequesting && <Loader2 className="animate-spin" />}
                <span className="uppercase">
                  {t('components.buttons.accept')}
                </span>
              </Button>
            }
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
