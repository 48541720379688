import { Navigate, Outlet, RouteObject } from 'react-router-dom'
import { MESSAGING_ROUTES } from './messaging/messaging.route'
import AuthGuard from '@/lib/auth/permissions/auth-guard'
import { Suspense } from 'react'
import FullPageLoaderTemplate from '@templates/full-page-loader.template'
import { USER_ROLE } from '@/lib/auth/auth-types'
import ParentLayout from './parent.layout'

export const PARENT_HOMEPAGE = '/dashboard'
export const PARENT_ROUTES: RouteObject[] = [
  {
    path: '',
    element: (
      <AuthGuard allowedRoles={[USER_ROLE.GUARDIAN]}>
        <ParentLayout>
          <Suspense fallback={<FullPageLoaderTemplate />}>
            <Outlet />
          </Suspense>
        </ParentLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate replace to={PARENT_HOMEPAGE} />,
      },
      {
        path: 'dashboard',
        lazy: () => import('./dashboard/dashboard.page'),
      },
      {
        path: 'news',
        // lazy: () => import("./feed/feed.page"),
        children: [
          {
            path: '',
            lazy: () => import('./news/news.page'),
          },
          {
            path: ':id',
            lazy: () => import('./news/news-detail/news-detail.page'),
          },
        ],
      },

      // {
      //   path: 'messaging',
      //   ...MESSAGING_ROUTES,
      // },
      {
        path: 'student',
        children: [
          {
            index: true,
            element: <Navigate replace to={'/student/activities'} />,
          },
          {
            path: 'activities',
            children: [
              {
                path: '',
                lazy: () => import('./student/activites/activities.page'),
              },
              {
                path: ':id',
                lazy: () =>
                  import('./student/activites/page-detail/page-detail.page'),
              },
            ],
          },
          {
            path: 'timetable',
            lazy: () => import('./student/timetable/timetable.page'),
          },
          {
            path: 'grades',
            lazy: () => import('./student/grades/grades.page'),
          },
          {
            path: 'attendance',
            lazy: () => import('./student/attendance/attendance.page'),
          },
          {
            path: 'health',
            lazy: () => import('./student/health/health.page'),
          },
          {
            path: 'bus',
            lazy: () => import('./student/bus/bus.page'),
          },
          {
            path: 'menu',
            children: [
              {
                path: '',
                lazy: () => import('./student/menu/menu.page'),
              },
              {
                path: ':id',
                lazy: () =>
                  import('./student/menu/menu-detail/menu-detail.page'),
              },
            ],
          },
          {
            path: 'reports',
            children: [
              {
                index:true,
                element: <Navigate replace to={'/student/reports/tree'}/>
              },
              {
                path: 'tree/*',
                lazy: () => import('./student/reports/reports-tree.page')
              },
              {
                path: 'blob/*',
                lazy: () => import('./student/reports/reports-blob.page')
              },
            ],
          },
        ],
      },
      {
        path: 'forms',
        children: [
          {
            index: true,
            element: <Navigate replace to={'/forms/policy-documents'} />,
          },
          {
            path: 'leave-request',
            lazy: () => import('./forms/leave-request/leave-request.page'),
          },
          {
            path: 'policy-documents',
            children: [
              {
                path: '',
                lazy: () =>
                  import('./forms/policy-documents/policy-documents.page'),
              },
              {
                path: ':id',
                lazy: () =>
                  import(
                    './forms/policy-documents/document-detail/document-detail.page'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'notifications',
        lazy: () => import('./notifications/notifications.page'),
      },
      {
        path: 'contacts',
        lazy: () => import('./contacts/contacts.page'),
      },
      {
        path: 'settings',
        lazy: () => import('./settings/settings.page'),
      },
    ],
  },
]
