import { useGetCourseClassesByUser } from '@/api/courseClass/use-get-course-classes-by-user'
import { useGetSchoolClassesByUser } from '@/api/schoolClass/use-get-school-classes-by-user'
import { CourseClassExtend } from '@/types/Extends/CourseClass'
import { SchoolClassExtend } from '@/types/Extends/SchoolClass'
import { SISCourseClass } from '@/types/SIS/SISCourseClass'
import { SISSchoolClass } from '@/types/SIS/SISSchoolClass'
import { SISStudent } from '@/types/SIS/SISStudent'
import { useFrappeGetCall } from 'frappe-react-sdk'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useAuthContext } from '../auth/useAuth'

interface AdminContextProps {
  schoolClasses?: SchoolClassExtend[]
}

export const AdminContext = createContext<AdminContextProps>({
  schoolClasses: undefined,
})

export const useAdmin = () => {
  const context = React.useContext(AdminContext)
  if (!context) {
    throw new Error('useChildren must be used within a AdminProvider')
  }
  return context
}

export const AdminProvider = ({ children }: React.PropsWithChildren) => {
  const { currentUser } = useAuthContext()
  const {
    data: schoolClasses,
    isLoading: schoolClassLoading,
    error: errSchoolClasses,
  } = useGetSchoolClassesByUser(!currentUser)
  return (
    <>
      {useMemo(
        () => (
          <AdminContext.Provider
            value={{
              schoolClasses: schoolClasses,
            }}
          >
            {children}
          </AdminContext.Provider>
        ),
        [JSON.stringify(schoolClasses)],
      )}
    </>
  )
}
