import { HTMLAttributes, ReactNode, useMemo, useState, type FC } from 'react'
import { cn } from '@/core/utils/shadcn-utils'
import { Checkbox } from '@atoms/checkbox'
import { PageChangeEvent } from '@react-pdf-viewer/core'
import { useTranslation } from 'react-i18next'
import { ScrollArea } from '@atoms/scroll-area'
import { PDFReader } from '@molecules/pdf-reader'
import env from '@/config/env'
import { useSettingsContext } from '@/lib/auth/settings-provider'
import { Button } from '@atoms/button'
import { useTheme } from '@/lib/shadcn/theme-provider'
import { buildUrlWithParams } from '@/lib/utils/common'

export type CheckTermsConditionsProps = HTMLAttributes<HTMLDivElement> & {
  onChecked?: (checked: boolean) => void
  action?: ReactNode
}

export const CheckTermsConditions: FC<CheckTermsConditionsProps> = ({
  onChecked,
  className,
  action,
}) => {
  const { theme } = useTheme()
  const { terms_and_conditions } = useSettingsContext()
  const { t } = useTranslation()
  const [isAccept, setIsAccept] = useState(false)
  // const [isRead, setIsRead] = useState(false)

  // const handleChangePage = (e: PageChangeEvent) => {
  //   if (!isRead && e.currentPage + 1 === e.doc.numPages) {
  //     setIsRead(true)
  //   }
  // }

  return (
    <div className={cn('flex h-full flex-col', className)}>
      <div className="relative flex-1 ">
        <div className="h-full w-full">
          {terms_and_conditions && (
            <iframe
              className="h-full w-full bg-background"
              src={buildUrlWithParams(terms_and_conditions, { mode: theme })}
              allow="fullscreen"
            />
          )}
        </div>
      </div>
      <div className="mt-3 flex items-center justify-between gap-1">
        <label
          htmlFor="accept"
          className={cn('inline-flex cursor-pointer items-center gap-2')}
        >
          <Checkbox id="accept" onCheckedChange={onChecked} />
          <span className="text-xs">
            {t('components.notification.accept_term.description', {
              object: '',
            })}
            <b className="ml-1    ">{t('common.terms_conditions')}</b>
          </span>
        </label>
        {action}
      </div>
    </div>
  )
}
