import { FC, ReactNode, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '@/lib/auth/useAuth'
import NoPermissionState from '@features/states/no-permisson-state'
import { USER_ROLE } from '@/lib/auth/auth-types'

type AuthGuardProps = {
  allowedRoles: USER_ROLE[]
  children: ReactNode
}

const AuthGuard: FC<AuthGuardProps> = ({ children, allowedRoles }) => {
  const { currentUser, userRole, isLoggingOut } = useAuthContext()
  const location = useLocation()
  const loginPath = '/auth/login'

  const redirectToLogin = useMemo(() => {
    const returnTo = new URLSearchParams({ returnTo: location.pathname }).toString()
    return `${loginPath}?${returnTo}`
  }, [location.pathname])

  if (!currentUser) {
    return <Navigate replace to={isLoggingOut ? loginPath : redirectToLogin} />
  }

  if (!userRole || !allowedRoles.includes(userRole) && !allowedRoles.includes(USER_ROLE.ALL)) {
    return <NoPermissionState />
  }

  return <>{children}</>
}

export default AuthGuard
