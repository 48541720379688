import { useFrappePostCall, useSWRConfig } from 'frappe-react-sdk'

import { FRAPPE_APIS } from '../api.config'
import { objectToFormData } from '@/lib/utils/common'
import { SISFeedback } from '@/types/SIS/SISFeedback'

export type PayloadType = {
  title?: string
  content: string
  files: File[]
}

export const useSendFeedback = () => {
  const { call } = useFrappePostCall<{
    message: SISFeedback
  }>(FRAPPE_APIS.SEND_FEEDBACK.METHOD_STRING)

  const handleSubmit = async (data: PayloadType) => {
    try {
      const res = await call({
        doc: data,
      })
      return Promise.resolve(res.message)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return { handleSubmit }
}
