import { HTMLAttributes, PropsWithChildren, useMemo, type FC } from 'react'

import {
  BookOpen,
  Bus,
  Calendar,
  ChatsCircle,
  CheckSquare,
  ForkKnife,
  Gear,
  Heart,
  House,
  Lifebuoy,
  Newspaper,
  NotePencil,
  PersonSimpleRun,
  CirclesThreePlus,
  Scroll,
  Note,
} from 'phosphor-react'
import { cn } from '@/core/utils/shadcn-utils'
import NavMenu, { ItemOptionsType, NavItem } from '@molecules/nav-menu'
import { useLocation } from 'react-router-dom'
import { useLocales } from '@/core/hooks'
import { FeedbackModal } from '@features/feedback/feedback-modal'
import useGetRoutes from '@/core/hooks/use-routes'
import { filterAccessMenu } from '@/lib/auth/permissions/has-access'
import { SISRoleType } from '@/lib/auth/auth-types'
import { useAuthContext } from '@/lib/auth/useAuth'

export type NavigationBarProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren & {
    items?: ItemOptionsType[]
  }

export const ParentNavigationBar: FC<NavigationBarProps> = (props) => {
  const { t } = useLocales()
  const location = useLocation()
  const { userSISRoles } = useAuthContext()
  const { getRoute } = useGetRoutes()

  const MENU: ItemOptionsType[] = useMemo(
    () => [
      {
        key: 'home',
        label: t('components.menu.dashboard'),
        path: getRoute.dashboard(),
        icon: (active) => (
          <House size={30} weight={active ? 'fill' : 'regular'} />
        ),
      },
      {
        key: 'news',
        label: t('components.menu.news_events'),
        path: getRoute.news(),
        icon: (active) => (
          <Newspaper size={30} weight={active ? 'fill' : 'regular'} />
        ),
      },
      {
        key: 'settings',
        label: t('components.menu.settings'),
        path: getRoute.settings(),
        icon: (active) => (
          <Gear size={30} weight={active ? 'fill' : 'regular'} />
        ),
      },
      {
        key: 'policy-documents',
        label: t('components.menu.policy-documents'),
        path: getRoute.policy(),
        icon: (active) => (
          <Scroll
            className="text-brand-blue"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'activities',
        label: t('components.menu.activities'),
        path: getRoute.activities(),
        icon: (active) => (
          <PersonSimpleRun
            className="text-brand-orange"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'timetable',
        label: t('components.menu.timetable'),
        path: getRoute.timetable(),
        icon: (active) => (
          <Calendar
            className="text-brand-yellow"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'attendance',
        label: t('components.menu.attendance'),
        path: getRoute.attendance(),
        icon: (active) => (
          <CheckSquare
            className="text-brand-green"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'food_menu',
        label: t('components.menu.food_menu'),
        path: getRoute.menu(),
        icon: (active) => (
          <ForkKnife
            className="text-brand-violet"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'feedback',
        label: t('components.menu.feedback'),
        path: getRoute.settings({ tab: 'support', open_feedback: 'true' }),
        icon: (active) => (
          <Lifebuoy
            className="text-brand-orange"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'chat',
        label: t('components.menu.chat'),
        path: '/student/chat',
        icon: (active) => (
          <ChatsCircle
            className="text-brand-blue"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
        disabled: true,
      },
      {
        key: 'leave-request',
        label: t('components.menu.leave_request'),
        path: '/forms/leave-request',
        icon: (active) => (
          <NotePencil
            className="text-brand-teal"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
        disabled: true,
      },
      {
        key: 'report',
        label: t('components.menu.report'),
        path: getRoute.studentReportsTree(),
        icon: (active) => (
          <Note
            className="text-brand-red"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
        // disabled: true,
      },
    ],
    [t, userSISRoles],
  )

  return (
    <NavigationBar {...props}>
      <NavMenu
        className="w-full justify-around gap-5"
        items={MENU || []}
        maxItems={4}
        moreItem={{
          key: 'more-menu',
          label: t('components.menu.more_menu'),
          icon: () => (
            <CirclesThreePlus className="text-brand-yellow" size={30} />
          ),
        }}
        active={(item) =>
          item.path ? location.pathname.includes(item.path) : false
        }
        activeClassName={{
          text: 'text-primary',
          background: 'rounded-none',
        }}
        mode="horizontal"
        itemDirection="vertical"
      />
    </NavigationBar>
  )
}

export const TeacherNavigationBar: FC<NavigationBarProps> = (props) => {
  const { t } = useLocales()
  const location = useLocation()
  const { getRoute } = useGetRoutes()

  const MENU: ItemOptionsType[] = useMemo(
    () => [
      {
        key: 'home',
        label: t('components.menu.dashboard'),
        path: getRoute.dashboard(),
        icon: (active) => (
          <House size={30} weight={active ? 'fill' : 'regular'} />
        ),
      },
      {
        key: 'news',
        label: t('components.menu.news_events'),
        path: getRoute.news(),
        icon: (active) => (
          <Newspaper size={30} weight={active ? 'fill' : 'regular'} />
        ),
      },
      {
        key: 'settings',
        label: t('components.menu.settings'),
        path: getRoute.settings(),
        icon: (active) => (
          <Gear size={30} weight={active ? 'fill' : 'regular'} />
        ),
      },
      {
        key: 'policy-documents',
        label: t('components.menu.policy-documents'),
        path: getRoute.policy(),
        icon: (active) => (
          <Scroll
            size={30}
            className="text-brand-blue"
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'activities',
        label: t('components.menu.activities'),
        path: getRoute.activities(),
        icon: (active) => (
          <PersonSimpleRun
            className="text-brand-orange"
            size={30}
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'timetable',
        label: t('components.menu.timetable'),
        path: getRoute.timetable(),
        icon: (active) => (
          <Calendar
            size={30}
            className="text-brand-yellow"
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'attendance',
        label: t('components.menu.attendance'),
        path: getRoute.attendance(),
        icon: (active) => (
          <CheckSquare
            size={30}
            className="text-brand-green"
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'feedback',
        label: t('components.menu.feedback'),
        path: getRoute.settings({ tab: 'support', open_feedback: 'true' }),
        icon: (active) => (
          <Lifebuoy
            size={30}
            className="text-brand-orange"
            weight={active ? 'fill' : 'regular'}
          />
        ),
      },
      {
        key: 'leave-request',
        label: t('components.menu.leave_request'),
        path: '/forms/leave-request',
        icon: (active) => (
          <NotePencil
            size={30}
            className="text-brand-teal"
            weight={active ? 'fill' : 'regular'}
          />
        ),
        disabled: true,
      },
    ],
    [t],
  )

  return (
    <NavigationBar {...props}>
      <NavMenu
        className="w-full justify-around gap-5"
        items={MENU || []}
        maxItems={4}
        moreItem={{
          key: 'more-menu',
          label: t('components.menu.more_menu'),
          icon: () => (
            <CirclesThreePlus className="text-brand-yellow" size={30} />
          ),
        }}
        active={(item) =>
          item.path ? location.pathname.includes(item.path) : false
        }
        activeClassName={{
          text: 'text-brand-secondary',
          background: 'rounded-none',
        }}
        mode="horizontal"
        itemDirection="vertical"
      />
    </NavigationBar>
  )
}

export const AdminNavigationBar: FC<NavigationBarProps> = (props) => {
  const { t } = useLocales()
  const location = useLocation()
  const { getRoute } = useGetRoutes()
  const { userSISRoles } = useAuthContext()

  const MENU: ItemOptionsType[] = useMemo(
    () =>
      filterAccessMenu(userSISRoles,[
        {
          key: 'news',
          label: t('components.menu.news_events'),
          path: getRoute.news(),
          icon: (active: boolean) => (
            <Newspaper size={30} weight={active ? 'fill' : 'regular'} />
          ),
        },
        {
          key: 'policy-documents',
          label: t('components.menu.policy-documents'),
          path: getRoute.policy(),
          icon: (active: boolean) => (
            <Scroll size={30} weight={active ? 'fill' : 'regular'} />
          ),
        },
        {
          key: 'settings',
          label: t('components.menu.settings'),
          path: getRoute.settings(),
          icon: (active: boolean) => (
            <Gear size={30} weight={active ? 'fill' : 'regular'} />
          ),
        },
        {
          key: 'activities',
          label: t('components.menu.activities'),
          path: getRoute.activities(),
          icon: (active: boolean) => (
            <PersonSimpleRun
              className="text-brand-orange"
              size={30}
              weight={active ? 'fill' : 'regular'}
            />
          ),
          allowedRoles: [SISRoleType.SIS_CONTENT_CREATOR],
        },
        {
          key: 'attendance',
          label: t('components.menu.attendance'),
          path: getRoute.attendance(),
          icon: (active: boolean) => (
            <CheckSquare
              size={30}
              className="text-brand-green"
              weight={active ? 'fill' : 'regular'}
            />
          ),
          allowedRoles: [SISRoleType.SIS_ATTENDANCE_MANAGER],
        },
        {
          key: 'food_menu',
          label: t('components.menu.food_menu'),
          path: getRoute.menu(),
          icon: (active: boolean) => (
            <ForkKnife
              className="text-brand-violet"
              size={30}
              weight={active ? 'fill' : 'regular'}
            />
          ),
        },
        {
          key: 'feedback',
          label: t('components.menu.feedback'),
          path: getRoute.settings({ tab: 'support', open_feedback: 'true' }),
          icon: (active: boolean) => (
            <Lifebuoy
              size={30}
              className="text-brand-orange"
              weight={active ? 'fill' : 'regular'}
            />
          ),
        },
      ]),
    [t],
  )

  return (
    <NavigationBar {...props}>
      <NavMenu
        className="w-full justify-around gap-5"
        items={MENU || []}
        maxItems={4}
        moreItem={{
          key: 'more-menu',
          label: t('components.menu.more_menu'),
          icon: (active) => (
            <CirclesThreePlus
              className="text-brand-yellow"
              size={30}
              weight={active ? 'fill' : 'regular'}
            />
          ),
        }}
        active={(item) =>
          item.path ? location.pathname.includes(item.path) : false
        }
        activeClassName={{
          text: 'text-brand-teal',
          background: 'rounded-none',
        }}
        mode="horizontal"
        itemDirection="vertical"
      />
    </NavigationBar>
  )
}

export const NavigationBar: FC<NavigationBarProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={cn(
        'fixed bottom-0 z-10 flex w-full overflow-hidden border border-transparent border-t-border bg-card shadow-sm md:sticky',
        className,
      )}
    >
      <div className="mx-auto w-full max-w-xl">{children}</div>
    </div>
  )
}
