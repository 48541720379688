import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  RefAttributes,
  useState,
  type FC,
} from 'react'
import { cn } from '@/core/utils/shadcn-utils'
import { Input, InputProps } from '@atoms/input'
import { Button } from '@atoms/button'
import { EyeIcon, EyeOffIcon } from 'lucide-react'

type InputGroupProps = {
  prefix?: (() => ReactNode) | ReactNode
  suffix?: (() => ReactNode) | ReactNode
  inputClassName?: string
}

export const InputGroup = forwardRef<
  HTMLInputElement,
  Omit<InputProps,'prefix'> & InputGroupProps
>(({ className, inputClassName, prefix, suffix, ...props }, ref) => {
  return (
    <div className={cn('relative', className)}>
      <span className="absolute left-0 top-0 flex h-full items-center">
        {typeof prefix === 'function' ? prefix?.() : prefix}
      </span>
      <Input ref={ref} className={cn(inputClassName)} {...props} />
      <span className="absolute right-0 top-0 flex h-full items-center">
        {typeof suffix === 'function' ? suffix?.() : suffix}
      </span>
    </div>
  )
})
