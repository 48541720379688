import React, { FC, useMemo } from 'react'
import { ChatsCircle, Note, PersonSimpleRun, Scroll } from 'phosphor-react'
import { ItemOptionsType } from '@molecules/nav-menu'
import {
  Bell,
  BookOpen,
  Bus,
  Calendar,
  Chat,
  CheckSquare,
  ForkKnife,
  Gear,
  Heart,
  House,
  Newspaper,
  NotePencil,
  SquaresFour,
} from 'phosphor-react'
import { useLocales } from '@/core/hooks'
import { SideBar, SideBarProps } from '@molecules/side-bar'
import useGetRoutes from '@/core/hooks/use-routes'
import { filterAccessMenu } from '@/lib/auth/permissions/has-access'
import { SISRoleType } from '@/lib/auth/auth-types'
import { useAuthContext } from '@/lib/auth/useAuth'

export const ParentSideBar: FC<SideBarProps> = (props) => {
  const { t } = useLocales()
  const { getRoute } = useGetRoutes()

  const MAIN_MENU: ItemOptionsType[] = useMemo(
    () => [
      {
        key: 'dashboard',
        label: t('components.menu.dashboard'),
        path: getRoute.dashboard(),
        icon: () => (
          <SquaresFour
            size={20}
            weight={'regular'}
            className="text-brand-green"
          />
        ),
      },
      {
        key: 'news',
        label: t('components.menu.news_events'),
        path: getRoute.news(),
        icon: () => (
          <Newspaper size={20} weight={'regular'} className="text-brand-red" />
        ),
      },
      {
        key: 'policy-documents',
        label: t('components.menu.policy-documents'),
        path: getRoute.policy(),
        icon: () => (
          <Scroll size={20} weight={'regular'} className="text-brand-blue" />
        ),
      },
      {
        key: 'sm',
        label: t('student_management'),
        type: 'group',
        children: [
          {
            key: 'activities',
            label: t('components.menu.activities'),
            path: getRoute.activities(),
            icon: () => (
              <PersonSimpleRun
                size={20}
                weight={'regular'}
                className="text-brand-orange"
              />
            ),
          },
          {
            key: 'timetable',
            label: t('components.menu.timetable'),
            path: getRoute.timetable(),
            icon: () => (
              <Calendar
                size={20}
                weight={'regular'}
                className="text-brand-yellow"
              />
            ),
          },
          {
            key: 'attendance',
            label: t('components.menu.attendance'),
            path: getRoute.attendance(),
            icon: () => (
              <CheckSquare
                size={20}
                weight={'regular'}
                className="text-brand-green"
              />
            ),
          },
          {
            key: 'food_menu',
            label: t('components.menu.food_menu'),
            path: getRoute.menu(),
            icon: () => (
              <ForkKnife
                size={20}
                weight={'regular'}
                className="text-brand-violet"
              />
            ),
          },
          {
            key: 'report',
            label: t('components.menu.report'),
            path: getRoute.studentReportsTree(),
            icon: () => (
              <Note size={20} weight={'regular'} className="text-brand-red" />
            ),
          },
          {
            key: 'chat',
            label: t('components.menu.chat'),
            path: '/messaging',
            icon: () => (
              <ChatsCircle
                size={20}
                weight={'regular'}
                className="text-brand-blue"
              />
            ),
            disabled: true,
          },
          {
            key: 'leave-request',
            label: t('components.menu.leave_request'),
            path: '/forms/leave-request',
            icon: () => (
              <NotePencil
                size={20}
                weight={'regular'}
                className="text-brand-teal"
              />
            ),
            disabled: true,
          },
        ],
      },
    ],
    [t],
  )
  const SYSTEM_MENU: ItemOptionsType[] = useMemo(
    () => [
      {
        key: 'notifications',
        label: t('components.menu.notifications'),
        path: '/notifications',
        icon: () => (
          <Bell size={20} weight={'regular'} className="text-brand-yellow" />
        ),
        disabled: true,
      },
      {
        key: 'settings',
        label: t('components.menu.settings'),
        path: getRoute.settings(),
        icon: () => <Gear size={20} weight={'regular'} className="text-gray" />,
      },
    ],
    [t],
  )
  return <SideBar mainMenu={MAIN_MENU} bottomMenu={SYSTEM_MENU} {...props} />
}

export const TeacherSideBar: FC<SideBarProps> = (props) => {
  const { t } = useLocales()
  const { getRoute } = useGetRoutes()

  const MAIN_MENU: ItemOptionsType[] = useMemo(
    () => [
      {
        key: 'Dashboard',
        label: t('components.menu.dashboard'),
        path: getRoute.dashboard(),
        icon: () => (
          <SquaresFour
            size={20}
            weight={'regular'}
            className="text-brand-green"
          />
        ),
      },
      {
        key: 'News',
        label: t('components.menu.news_events'),
        path: getRoute.news(),
        icon: () => (
          <Newspaper size={20} weight={'regular'} className="text-brand-red" />
        ),
      },
      {
        key: 'Policy-documents',
        label: t('components.menu.policy-documents'),
        path: getRoute.policy(),
        icon: () => (
          <Scroll size={20} weight={'regular'} className="text-brand-blue" />
        ),
      },
      {
        key: 'sm',
        label: t('common.class_management'),
        type: 'group',
        children: [
          {
            key: 'Activities',
            label: t('components.menu.activities'),
            path: getRoute.activities(),
            icon: () => (
              <PersonSimpleRun
                size={20}
                weight={'regular'}
                className="text-brand-orange"
              />
            ),
          },
          {
            key: 'timetable',
            label: t('components.menu.timetable'),
            path: getRoute.timetable(),
            icon: () => (
              <Calendar
                size={20}
                weight={'regular'}
                className="text-brand-blue"
              />
            ),
          },
          {
            key: 'attendance',
            label: t('components.menu.attendance'),
            path: getRoute.attendance(),
            icon: () => (
              <CheckSquare
                size={20}
                weight={'regular'}
                className="text-brand-teal"
              />
            ),
          },
          {
            key: 'student-reports',
            label: t('components.menu.student_reports'),
            path: '/teacher/student-reports/tree',
            icon: () => (
              <Note size={20} weight={'regular'} className="text-brand-red" />
            ),
            disabled: true
          },
        ],
      },
    ],
    [t],
  )

  const SYSTEM_MENU: ItemOptionsType[] = useMemo(
    () => [
      {
        key: 'notifications',
        label: t('components.menu.notifications'),
        path: '/notifications',
        icon: () => (
          <Bell size={20} weight={'regular'} className="text-brand-yellow" />
        ),
        disabled: true,
      },
      {
        key: 'settings',
        label: t('components.menu.settings'),
        path: getRoute.settings(),
        icon: () => (
          <Gear size={20} weight={'regular'} className="text-brand-gray" />
        ),
      },
    ],
    [t],
  )
  return <SideBar mainMenu={MAIN_MENU} bottomMenu={SYSTEM_MENU} {...props} />
}

export const AdminSideBar: FC<SideBarProps> = (props) => {
  const { t } = useLocales()
  const { userSISRoles } = useAuthContext()
  const { getRoute } = useGetRoutes()

  const MAIN_MENU: ItemOptionsType[] = useMemo(
    () =>
      filterAccessMenu(userSISRoles, [
        {
          key: 'News',
          label: t('components.menu.news_events'),
          path: getRoute.news(),
          icon: () => (
            <Newspaper
              size={20}
              weight={'regular'}
              className="text-brand-red"
            />
          ),
        },
        {
          key: 'Policy-documents',
          label: t('components.menu.policy-documents'),
          path: getRoute.policy(),
          icon: () => (
            <Scroll size={20} weight={'regular'} className="text-brand-blue" />
          ),
        },
        {
          key: 'food_menu',
          label: t('components.menu.food_menu'),
          path: getRoute.menu(),
          icon: () => (
            <ForkKnife
              size={20}
              weight={'regular'}
              className="text-brand-violet"
            />
          ),
        },
        {
          key: 'sm',
          label: t('common.class_management'),
          type: 'group',
          children: [
            {
              key: 'Activities',
              label: t('components.menu.activities'),
              path: getRoute.activities(),
              icon: () => (
                <PersonSimpleRun
                  size={20}
                  weight={'regular'}
                  className="text-brand-orange"
                />
              ),
              allowedRoles: [SISRoleType.SIS_CONTENT_CREATOR],
            },
            {
              key: 'attendance',
              label: t('components.menu.attendance'),
              path: getRoute.attendance(),
              icon: () => (
                <CheckSquare
                  size={20}
                  weight={'regular'}
                  className="text-brand-teal"
                />
              ),
              allowedRoles: [SISRoleType.SIS_ATTENDANCE_MANAGER],
            },
          ],
        },
      ]),
    [t, userSISRoles],
  )

  const SYSTEM_MENU: ItemOptionsType[] = useMemo(
    () => [
      {
        key: 'settings',
        label: t('components.menu.settings'),
        path: getRoute.settings(),
        icon: () => <Gear size={20} weight={'regular'} />,
      },
    ],
    [t],
  )
  return <SideBar mainMenu={MAIN_MENU} bottomMenu={SYSTEM_MENU} {...props} />
}
