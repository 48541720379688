import { useFrappeGetCall } from 'frappe-react-sdk'

import { FRAPPE_APIS } from '../api.config'
import { SISCourseClass } from '@/types/SIS/SISCourseClass'
import { CourseClassExtend } from '@/types/Extends/CourseClass'

export const useGetCourseClassesByUser = (skip?: boolean) => {
  const { data, error, isLoading, isValidating, mutate } = useFrappeGetCall<{
    message: CourseClassExtend[]
  }>(
    FRAPPE_APIS.GET_COURSE_CLASSES_BY_USER.METHOD_STRING,
    {},
    !skip ? `${FRAPPE_APIS.GET_COURSE_CLASSES_BY_USER.SWR_KEY}` : null,
  )

  return { error, data: data?.message, isLoading, isValidating }
}
