import { Button } from '@atoms/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@atoms/dialog'
import React, {
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { ScrollArea } from '@radix-ui/themes'
import { Separator } from '@atoms/separator'
import { Loader2 } from 'lucide-react'
import { useSWRConfig } from 'frappe-react-sdk'
import { FRAPPE_APIS } from '@/api/api.config'
import { useToast } from '@atoms/use-toast'
import { useNavigate } from 'react-router-dom'
import { SISClassFeed } from '@/types/SIS/SISClassFeed'
import { useTranslation } from 'react-i18next'
import { ParentInformationForm } from './sign-up.form'
import { UpdateProfileForm } from '@features/account/update-profile-form'
import { useFormUpdateProfile } from './hooks/use-form-update-profile'
import { StepItem, Stepper } from '@molecules/stepper'
import { Tabs } from '@atoms/tabs'
import { useLocales } from '@/core/hooks'
import { capitalizeAllWords } from '@/lib/utils/common'
import AlertStatus from '@molecules/alert-status'

type Props = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren & {
    title?: string
    desc?: string
    open?: boolean
    topContent?: ReactNode
    cancelText?: string
    submitText?: string
    onOpenChange?: (open: boolean) => void
  }
export default function EditProfileModal({
  open: openValue,
  className,
  title,
  desc,
  topContent,
  cancelText,
  submitText,
  children,
  onOpenChange,
}: Props) {
  const [open, setOpen] = useState(false)
  const { handleSubmit, loading } = useFormUpdateProfile()
  const { toast } = useToast()
  const { t, currentLanguage } = useLocales()

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    onOpenChange?.(isOpen)
  }

  const onSubmit = async (data: any) => {
    try {
      await handleSubmit(data)
      setOpen(false)
    } catch (error) {
      console.error('ERROR', error)
      toast({
        variant: 'destructive',
        title: 'Something went wrong!',
        description: JSON.stringify(error),
      })
    }
  }

  useEffect(() => {
    setOpen(openValue || false)
  }, [openValue])

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="flex h-[85vh] md:h-[90vh] w-[1024px] max-w-full flex-col">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold">
            {title ?? t('components.modals.update_profile.heading')}
          </DialogTitle>
          <DialogDescription>
            {desc ?? t('components.modals.update_profile.description')}
          </DialogDescription>
        </DialogHeader>
        <div className="flex-1 overflow-x-hidden md:pr-2">
          {topContent}

          <UpdateProfileForm onSubmit={onSubmit}>
            <div className="sticky bottom-0 flex flex-col-reverse justify-end gap-x-2 gap-y-1 bg-card pt-2 md:flex-row">
              <Button
                type="button"
                className="space-x-2"
                variant="outline"
                onClick={() => handleOpenChange(false)}
              >
                <span>{cancelText ?? t('components.buttons.close')}</span>
              </Button>
              <Button type="submit" className="space-x-2" disabled={loading}>
                {loading && <Loader2 className="animate-spin" />}
                <span>{submitText ?? t('components.buttons.update')}</span>
              </Button>
            </div>
          </UpdateProfileForm>
        </div>
      </DialogContent>
    </Dialog>
  )
}
