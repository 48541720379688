import { PropsWithChildren, useContext, type FC } from 'react'

import PageLayout from '@templates/page.layout'

import { AdminSideBar } from '@features/navigation/side-bar'
import { AdminNavigationBar } from '@features/navigation/navigation-bar'
import { FeedbackFloatingButton } from '@features/feedback/feedback-floating-button'
import { AdminProvider } from '@/lib/admin/admin-provider'

export default function AdminLayout({ children }: PropsWithChildren) {
  return (
    <AdminProvider>
      <PageLayout sidebar={<AdminSideBar />} bottom={<AdminNavigationBar />}>
        {children}
        <FeedbackFloatingButton className="hidden md:block" />
      </PageLayout>
    </AdminProvider>
  )
}
