import { HTMLAttributes, useState, type FC } from 'react'
import { cn } from '@/core/utils/shadcn-utils'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@atoms/dialog'
import { Button } from '@atoms/button'
import { Label } from '@atoms/label'
import { Input } from '@atoms/input'
import { Textarea } from '@atoms/textarea'
import {
  FileIcon,
  FileInput,
  FileSvgDraw,
  FileUploader,
  FileUploaderContent,
  FileUploaderItem,
  ImageSvgDraw,
} from '@molecules/file-uploader'
import useFeedbackForm from './hooks/use-feedback-form'
import { Form, FormField } from '@atoms/form'
import { useLocales, useResponsive } from '@/core/hooks'

import { formatBytes, shortenText } from '@/lib/utils/common'
import { File, FileImage, FileVideo, Lifebuoy, Smiley } from 'phosphor-react'
import { useModal } from '@/lib/shadcn/modal-provider'
import { FeedbackModal } from './feedback-modal'
import { Tooltip, TooltipTrigger, TooltipContent } from '@atoms/tooltip'

export type FeedbackFloatingButtonProps = HTMLAttributes<HTMLDivElement> & {}

export const FeedbackFloatingButton: FC<FeedbackFloatingButtonProps> = ({
  className,
}) => {
  return (
    <FeedbackModal>
      <Button
        variant="outline"
        className={cn(
          'fixed bottom-[200px] right-[15px] z-50 rounded-b-none origin-right rotate-[-90deg] border-0 bg-brand-orange hover:bg-brand-orange/90',
          className,
        )}
      >
        <div className="flex gap-2">
          <Smiley className="text-white" size={20} />
          <span className="text-white">FEEDBACK</span>
        </div>
      </Button>
    </FeedbackModal>
  )
}
