import GuestGuard from '@/lib/auth/permissions/guest-guard'
import AuthLayout from '@templates/auth.layout'
import FullPageLoaderTemplate from '@templates/full-page-loader.template'
import { lazy, Suspense } from 'react'
import { RouteObject, Navigate, Outlet } from 'react-router-dom'

export const AUTH_ROUTES: RouteObject[] = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <AuthLayout>
          <Suspense fallback={<FullPageLoaderTemplate />}>
            <Outlet />
          </Suspense>
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="login" />,
      },
      {
        path: 'login',
        lazy: () => import('./sign-in/page'),
      },
      // {
      //   path: "registration",
      //   lazy: () => import("./sign-up/sign-up.page"),
      // },
      {
        path: 'forgot-password',
        lazy: () => import('./forgot-password/forgot-password.page'),
      },
      {
        path: 'reset-password/:key',
        lazy: () => import('./reset-password/reset-password.page'),
      },
    ],
  },
]
