import { PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '@/lib/auth/useAuth'
import AuthGuard from './auth-guard'
import { USER_ROLE } from '../auth-types'
import { PARENT_HOMEPAGE } from '@/app/parent/parent.router'
import { TEACHER_HOMEPAGE } from '@/app/teacher/teacher.router'
import { ADMIN_HOMEPAGE } from '@/app/admin/admin.router'
import { Loader2 } from 'lucide-react'
import NoPermissionState from '@features/states/no-permisson-state'

export default function HomeRedirect() {
  const { currentUser, userRole } = useAuthContext()

  if (currentUser && !userRole)
    return <NoPermissionState />

  if (!currentUser )
    return <Navigate to="/auth/login"/>

  return (
    <AuthGuard allowedRoles={[USER_ROLE.ALL]}>
      {userRole === USER_ROLE.GUARDIAN && <Navigate to={PARENT_HOMEPAGE} />}
      {userRole === USER_ROLE.TEACHER && <Navigate to={TEACHER_HOMEPAGE} />}
      {userRole === USER_ROLE.ADMIN && <Navigate to={ADMIN_HOMEPAGE} />}
    </AuthGuard>
  )
}
