import React, { ReactElement, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IconProps } from 'phosphor-react'
import {
  ForwardRefExoticComponent,
  HTMLAttributes,
  ReactNode,
  type FC,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { cn } from '@/core/utils/shadcn-utils'
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
} from '@atoms/drawer'
import PegBoard from './pegboard'
import { VisuallyHidden } from '@radix-ui/themes'

type Props = {
  className?: string
  active?: (item: ItemOptionsType) => boolean
  activeClassName?: ActiveClassNameType
  items: ItemOptionsType[]
  itemDirection?: 'horizontal' | 'vertical'
  mode?: 'horizontal' | 'vertical'
  maxItems?: number
  moreItem?: ItemOptionsType
}

type NavItemProps = {
  label?: string | ReactNode
  className?: string
  active?: boolean
  activeClassName?: ActiveClassNameType
  path?: string
  icon?: ReactElement
  badge?: ReactNode
  vertical?: boolean
  disabled?: boolean
  onClick?: () => void
}

type ActiveClassNameType = {
  text?: string
  background?: string
}

export type ItemOptionsType = {
  key: string
  label: string
  path?: string
  icon?: (active?: boolean) => ReactElement
  type?: 'group' | 'divider'
  disabled?: boolean
  children?: ItemOptionsType[]
  allowedRoles?: string[]
}

export default function NavMenu({
  className,
  active = () => false,
  activeClassName,
  items,
  maxItems,
  moreItem,
  itemDirection = 'horizontal',
  mode = 'vertical',
}: Props) {
  const [open, setOpen] = useState(false)
  return (
    <div
      className={cn(
        'flex gap-2',
        {
          'flex-col': mode === 'vertical',
          'flex-row': mode === 'horizontal',
        },
        className,
      )}
    >
      {items
        ?.slice(
          0,
          (maxItems && items.length > maxItems && maxItems - 1) || items.length,
        )
        .map((_) => {
          if (_.children) {
            return (
              <React.Fragment key={_.key}>
                {mode === 'vertical' && _.children.length > 0 && (
                  <h4 className="text-md my-2 text-primary/70">{_.label}</h4>
                )}
                {_.children.map((child) => (
                  <NavItem
                    key={child.key}
                    label={child.label}
                    path={child.path}
                    icon={child.icon?.(active(child))}
                    disabled={child.disabled}
                    active={active(child)}
                    activeClassName={activeClassName}
                    vertical={itemDirection === 'vertical'}
                  />
                ))}
              </React.Fragment>
            )
          }
          return (
            <NavItem
              key={_.key}
              label={_.label}
              path={_.path}
              icon={_.icon?.(active(_))}
              disabled={_.disabled}
              active={active(_)}
              activeClassName={activeClassName}
              vertical={itemDirection === 'vertical'}
            />
          )
        })}
      {maxItems && maxItems < items.length && moreItem && (
        <NavItem
          key={moreItem.key}
          label={moreItem.label}
          path={moreItem.path}
          icon={moreItem.icon?.(active(moreItem))}
          disabled={moreItem.disabled}
          active={active(moreItem)}
          activeClassName={activeClassName}
          vertical={itemDirection === 'vertical'}
          onClick={() => setOpen(true)}
        />
      )}
      {maxItems && (
        <Drawer open={open} onOpenChange={($open) => setOpen($open)}>
          <DrawerContent className="outline-none">
            <DrawerTitle></DrawerTitle>
            <DrawerDescription></DrawerDescription>
            <PegBoard className="py-5">
              {items?.slice(maxItems - 1, items.length).map((_) => {
                return (
                  <NavItem
                    key={_.key}
                    label={_.label}
                    path={_.path}
                    icon={_.icon?.(active(_))}
                    disabled={_.disabled}
                    active={active(_)}
                    activeClassName={activeClassName}
                    vertical={itemDirection === 'vertical'}
                    onClick={() => setOpen(false)}
                  />
                )
              })}
            </PegBoard>
          </DrawerContent>
        </Drawer>
      )}
    </div>
  )
}

export const NavItem = ({
  label,
  className,
  path,
  active,
  activeClassName,
  icon,
  badge,
  vertical,
  disabled = false,
  onClick,
}: NavItemProps) => {
  const navigate = useNavigate()

  const handleClickItem = (path?: string) => {
    path && navigate(path)
    onClick?.()
  }

  return (
    <div
      className={cn(
        'cursor-pointer rounded-md p-2',
        active && (activeClassName?.background || 'bg-slate-300'),
        {
          'flex items-center gap-3': !vertical,
          'flex flex-col items-center': vertical,
          'opacity-50 hover:bg-transparent': disabled,
          // 'bg-brand-teal/10': active && vertical,
        },
        className,
      )}
      onClick={() => !disabled && handleClickItem(path)}
    >
      <div className={'relative'}>
        {icon &&
          React.cloneElement(icon, {
            className: cn(
              icon.props.className,
              active && activeClassName?.text,
            ),
          })}
        {badge}
      </div>
      <div
        className={cn(
          'line-clamp-2 text-center text-xs text-foreground md:line-clamp-1 md:text-left md:text-base',
          active && activeClassName?.text,
        )}
        title={label?.toString() || ''}
      >
        {label}
      </div>
    </div>
  )
}
