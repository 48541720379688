import { AuthContext } from '@/lib/auth/auth-provider'
import { zodResolver } from '@hookform/resolvers/zod'
import { FrappeError } from 'frappe-react-sdk'
import { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { format } from 'date-fns'
import { SISClassFeed } from '@/types/SIS/SISClassFeed'
import { PayloadType, useSendFeedback } from '@/api/feedback/use-feedback'
import { useUploadFeedbackFile } from '@/api/feedback/use-upload-feedback-file'

const FileSchema = z.instanceof(File)

const validationSchema = z.object({
  title: z.string(),
  content: z.string().min(1, { message: 'This field is required' }),
  files: FileSchema.array(),
})

export default function useFeedbackForm() {
  const [error, setError] = useState<FrappeError | null>(null)
  const { handleSubmit: SendFeedback } = useSendFeedback()
  const { handleUpload: UploadFile } = useUploadFeedbackFile()

  const form = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      title: '',
      content: '',
      files: [],
    },
  })

  const handleSubmit = async (data: PayloadType) => {
    try {
      let { name } = await SendFeedback(data)
      await UploadFile(name,data.files)
      return Promise.resolve()
    } catch (error: any) {
      setError(error)
      return Promise.reject(error)
    }
  }

  const reset = () => {
    setError(null)
    form.reset()
  }

  return {
    form,
    error,
    handleSubmit,
    reset,
  }
}
