import { FC, ReactNode, useMemo } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { useAuthContext } from '@/lib/auth/useAuth'

type Props = {
  children: ReactNode
}

const GuestGuard: FC<Props> = ({ children }) => {
  const { currentUser, prefixRoute } = useAuthContext()
  const [searchParams] = useSearchParams()

  const returnTo = useMemo(
    () => searchParams.get('returnTo') || `/${prefixRoute || ""}`,
    [searchParams, prefixRoute],
  )

  if (currentUser) {
    return <Navigate to={returnTo} replace />
  }

  return <>{children}</>
}

export default GuestGuard
