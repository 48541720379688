import { useUpdateProfile } from '@/api/account/use-update-profile'
import { REGEX } from '@/core/constant/regex'
import { useLocales } from '@/core/hooks'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  FrappeError,
} from 'frappe-react-sdk'
import {  useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

export type ProfilePayload = {
  last_name: string
  street:string
  common_name: string
  date_of_birth: string
  nationality: string
  email: string
  phone_number: string
  address: string
  province: string
  provinceCode: string
  district: string
  districtCode: string
  ward: string
  wardCode: string
  company: string
  job_title: string
}

export const useFormUpdateProfile = () => {
  const { t, currentLanguage } = useLocales()
  const [error, setError] = useState<FrappeError | null>(null)
  const { handleSubmit, loading } = useUpdateProfile()

  const validationSchema = useMemo(
    () =>
      z.object({
        last_name: z
          .string()
          .trim()
          .min(1, { message: t('components.inputs.last_name.err_required') }),
        first_name: z
          .string()
          .trim()
          .min(1, { message: t('components.inputs.name.err_required') }),
        date_of_birth: z.coerce.date().optional(),
        nationality: z.string().optional(),
        email: z
          .string()
          .trim()
          .min(1, { message: t('components.inputs.email.err_required') })
          .email({ message: t('components.inputs.email.err_invalid') }),
        phone_number: z
          .string()
          .trim()
          .min(1, { message: t('components.inputs.phone.err_required') })
          .regex(REGEX.phone, {
            message: t('components.inputs.phone.err_invalid'),
          }),
        street: z.string().trim().optional(),
        province: z.string().optional(),
        district: z.string().optional(),
        ward: z.string().optional(),
        company: z.string().trim().optional(),
        job_title: z.string().trim().optional(),
      }),
    [currentLanguage],
  )

  const form = useForm<z.infer<typeof validationSchema>>({
    resolver: zodResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      last_name: '',
      first_name: '',
      date_of_birth: undefined,
      nationality: 'Vietnam',
      email: '',
      phone_number: '',
      street: '',
      province: '{"name":"Thành phố Hồ Chí Minh","code":79}',
      district: undefined,
      ward: undefined,
      company: '',
      job_title: '',
    },
  })

  return {
    form,
    error,
    handleSubmit,
    loading,
  }
}
