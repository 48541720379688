import {
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
  type FC,
} from 'react'
import { cn } from '@/core/utils/shadcn-utils'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from '@atoms/dialog'
import { Button } from '@atoms/button'
import { Label } from '@atoms/label'
import { Input } from '@atoms/input'
import { Textarea } from '@atoms/textarea'
import {
  FileIcon,
  FileInput,
  FileSvgDraw,
  FileUploader,
  FileUploaderContent,
  FileUploaderItem,
  ImageSvgDraw,
} from '@molecules/file-uploader'
import useFeedbackForm from './hooks/use-feedback-form'
import { Form, FormField } from '@atoms/form'
import { useLocales, useResponsive } from '@/core/hooks'

import { formatBytes, shortenText } from '@/lib/utils/common'
import { File, FileImage, FileVideo, Lifebuoy } from 'phosphor-react'
import { useModal } from '@/lib/shadcn/modal-provider'
import { useLocation, useSearchParams } from 'react-router-dom'

export type FeedbackModalProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren & { defaultOpen?: boolean }

export const FeedbackModal: FC<FeedbackModalProps> = ({
  defaultOpen = false,
  className,
  children,
}) => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { showModal } = useModal()
  const { isDesktop } = useResponsive()
  const { t } = useLocales()
  const { form, handleSubmit } = useFeedbackForm()
  const [open, setOpen] = useState(defaultOpen)

  const onSuccess = () => {
    setOpen(false)
    form.reset()
    showModal({
      type: 'success',
      title: t('components.notification.send_feedback_success.heading'),
      desc: t('components.notification.send_feedback_success.description'),
      okButton: false,
    })
  }
  const onFailed = () => {
    setOpen(false)
    form.reset()
    showModal({
      type: 'error',
      title: t('components.notification.send_feedback_failed.heading'),
      desc: t('components.notification.send_feedback_failed.description'),
      okButton: false,
    })
  }

  const handleChangeOpen = ($open: boolean) => {
    setOpen($open)
    !$open && setSearchParams((params) => {
      params.delete('open_feedback')
      return params
    })
  }

  useEffect(() => {
    setOpen(!!searchParams.get('open_feedback'))
  }, [location])

  return (
    <Dialog open={open} onOpenChange={handleChangeOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="w-[360px] max-w-full bg-card md:w-[540px]">
        <div className="space-y-2">
          <DialogTitle>
            <p className="font-medium leading-none">
              {t('components.modals.send_feedback.heading')}
            </p>
          </DialogTitle>
          <DialogDescription className="text-sm text-muted-foreground">
            {t('components.modals.send_feedback.description')}
          </DialogDescription>
        </div>
        <Form {...form}>
          <form
            className="space-y-3"
            onSubmit={form.handleSubmit((data) =>
              handleSubmit(data).then(onSuccess).catch(onFailed),
            )}
          >
            <div className="max-h-[60vh] overflow-x-hidden px-1">
              <div className="flex flex-col gap-2">
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <div className="space-y-2">
                      <Label htmlFor="title">
                        {`${t('components.inputs.title.label')} (${t('common.optional').toLowerCase()})`}
                      </Label>
                      <Input
                        id="title"
                        placeholder={t('components.inputs.title.placeholder')}
                        className="col-span-2 h-8 bg-input/10"
                        {...field}
                      />
                    </div>
                  )}
                />
                <FormField
                  control={form.control}
                  name="content"
                  render={({ field }) => (
                    <div className="space-y-2">
                      <Label htmlFor="comment">
                        {t('components.inputs.comment_feedback.label')}
                      </Label>
                      <Textarea
                        id="comment"
                        placeholder={t(
                          'components.inputs.comment_feedback.placeholder',
                        )}
                        className="col-span-2 h-8 bg-input/10"
                        {...field}
                      />
                    </div>
                  )}
                />
                <FormField
                  control={form.control}
                  name="files"
                  render={({ field }) => (
                    <div className="space-y-2">
                      <Label htmlFor="attachments">{`${t('components.inputs.upload_images.label')} (${t('common.optional').toLowerCase()})`}</Label>
                      <FileUploader
                        id="attachments"
                        value={field.value}
                        onValueChange={field.onChange}
                        dropzoneOptions={{
                          accept: {
                            'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
                          },
                          maxFiles: 3,
                          maxSize: 1024 * 1024 * 4,
                          multiple: true,
                        }}
                        className="relative space-y-1"
                      >
                        <FileInput className="border border-dashed px-5 text-center">
                          <ImageSvgDraw
                            iconSize={30}
                            exts={['JPG', 'JPEG', 'PNG', 'GIF']}
                          />
                        </FileInput>
                        <FileUploaderContent className="">
                          {form.watch('files').map((file, i) => (
                            <FileUploaderItem key={i} index={i}>
                              <div className="flex items-center space-x-3">
                                <FileIcon type={file?.type} />
                                <div>
                                  <p className="text-sm">
                                    {shortenText(
                                      file?.name,
                                      isDesktop ? 40 : 20,
                                    )}
                                  </p>
                                  <p className="text-sm text-muted-foreground">
                                    {(file?.size && formatBytes(file.size)) ||
                                      'NaN'}
                                  </p>
                                </div>
                              </div>
                            </FileUploaderItem>
                          ))}
                        </FileUploaderContent>
                      </FileUploader>
                    </div>
                  )}
                />
              </div>
            </div>
            <DialogFooter className="px-1">
              <Button
                type="button"
                variant="outline"
                onClick={() => handleChangeOpen(false)}
              >
                {t('components.buttons.close')}
              </Button>
              <Button disabled={!form.formState.isValid}>
                {t('components.buttons.send_feedback')}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
