import * as React from 'react'
import { useEffect, useState, PropsWithChildren } from 'react'
import { useAuthContext } from '@/lib/auth/useAuth'
import { SISRoleType } from '../auth-types'
import { Loader2 } from 'lucide-react'

export interface UserPayload {
  id: string
  roles: SISRoleType[]
  permissions: string[]
}

export interface HasAccessProps {
  roles?: SISRoleType[]
  permissions?: string[]
  loadingSpinner?: React.ReactElement
  renderAuthFailed?: React.ReactElement
}

const HasAccess = ({
  roles,
  permissions,
  loadingSpinner = <Loader2 className="h-4 w-4 animate-spin"/>,
  renderAuthFailed,
  children,
}: PropsWithChildren<HasAccessProps>) => {
  const [hasAccess, setHasAccess] = useState(false)
  const [checking, setChecking] = useState<Boolean | null>(null)

  const { currentUser, userRole, userSISRoles, isLoading } = useAuthContext()

  useEffect(() => {
    if (!currentUser) return

    setChecking(true)

    // role check
    if (roles && userSISRoles && userSISRoles.length > 0) {
      const hasRole = userSISRoles.some((role: SISRoleType) =>
        roles.includes(role),
      )
      if (hasRole) setHasAccess(true)
    }

    // permission check
    // if (
    //   permissions &&
    //   currentUserPermissions &&
    //   currentUserPermissions.length > 0
    // ) {
    //   const intersection = currentUserPermissions.filter((permission: string) =>
    //     permissions.includes(permission),
    //   )
    //   if (intersection.length > 0) setHasAccess(true)
    // }

    setChecking(false)
  }, [roles, permissions, currentUser, userSISRoles])

  if (checking !== null) {
    if (!hasAccess && checking) {
      return loadingSpinner
    }

    if (hasAccess) {
      return children
    }

    if (renderAuthFailed) {
      return renderAuthFailed
    }

    return null
  } else {
    return loadingSpinner
  }
}

export const filterAccessMenu = (
  userSISRoles: SISRoleType[],
  items: any[],
): any[] => {
  return items
    .map((item) => {
      if (item.children) {
        return {
          ...item,
          children: filterAccessMenu(userSISRoles, item.children),
        }
      }
      return item
    })
    .filter(
      (item) =>
        !item.allowedRoles ||
        userSISRoles.some((role: SISRoleType) =>
          item.allowedRoles.includes(role),
        ),
    )
}

export default HasAccess
