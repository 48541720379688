import { PropsWithChildren, useContext, type FC } from 'react'

import PageLayout from '@templates/page.layout'

import { TeacherProvider } from '@/lib/teacher/teacher-provider'
import { TeacherSideBar } from '@features/navigation/side-bar'
import { TeacherNavigationBar } from '@features/navigation/navigation-bar'
import { FeedbackFloatingButton } from '@features/feedback/feedback-floating-button'

export default function TeacherLayout({ children }: PropsWithChildren) {
  return (
    <TeacherProvider>
      <PageLayout
        sidebar={<TeacherSideBar />}
        bottom={<TeacherNavigationBar />}
      >
        {children}
        <FeedbackFloatingButton className="hidden md:block" />
      </PageLayout>
    </TeacherProvider>
  )
}
